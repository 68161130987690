import { Box } from "@mui/system";
import { Button } from "@mui/material";
import CategoryName from "./CategoryName";
import Characteristics from "./Characteristics";
import { useEffect, useState } from "react";
var SHA1 = require("crypto-js/sha1");
import {
  getProductId,
  createProduct,
  insertProductCharacteristics,
  update_comp_lookup_record,
} from "../../../api/CompAdminAPI";

function EditArea({
  categories,
  categoryId,
  categoryName,
  uuid,
  productId,
  setShowEdit,
  setCategoryId,
  setCategoryName,
  setProductId,
  setClassifyConfidence,
}) {
  const [newCategoryId, setNewCategoryId] = useState();
  const [newCategoryName, setNewCategoryName] = useState();
  const [newCharacteristics, setNewCharacteristics] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const [compLookupId, setCompLookupId] = useState();

  useEffect(async () => {
    newCategoryId || newCharacteristics
      ? setDisableButton(false)
      : setDisableButton(true);
  }, [newCategoryId, newCharacteristics]);

  useEffect(async () => {
    setCompLookupId(uuid);
    console.log("editarea compLookupId: " + uuid);
    console.log("editarea categoryName: " + categoryName);
    console.log("editarea categoryId : " + categoryId);
    console.log("editarea productId : " + productId);
  }, []);

  return (
    <Box sx={{ bgcolor: "#eff5ef" }}>
      <Box>
        <h2>Modify Classification</h2>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <CategoryName
          categoryId={categoryId}
          categoryName={categoryName}
          categories={categories}
          setNewCategoryId={setNewCategoryId}
          setNewCategoryName={setNewCategoryName}
        />
        <Characteristics
          categoryId={newCategoryId ? newCategoryId : categoryId}
          productId={productId}
          setNewCharacteristics={setNewCharacteristics}
        />
      </Box>
      <Box sx={{ marginBottom: 5 }}>
        <Button
          disabled={disableButton}
          variant="contained"
          onClick={() => {
            saveProduct(
              newCategoryId,
              newCategoryName,
              newCharacteristics,
              compLookupId,
              setShowEdit,
              setCategoryName,
              setCategoryId,
              setProductId,
              setClassifyConfidence
            );
          }}
        >
          Save
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          variant="outlined"
          onClick={() => {
            setShowEdit(false);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}

async function saveProduct(
  newCategoryId,
  newCategoryName,
  newCharacteristics,
  compLookupId,
  setShowEdit,
  setCategoryName,
  setCategoryId,
  setProductId,
  setClassifyConfidence
) {
  // lookup new values to see if product already exists
  let newHash = "";
  if (newCharacteristics && newCharacteristics.length > 0) {
    newHash = await generateHash(newCharacteristics);
  }

  if (newCategoryId || newCharacteristics) {
    let productId = await getProductId(newCategoryId, newHash);

    if (productId && productId.length > 0) {
      console.log("product exists: " + productId);
      productId = productId[0].id;
    } else {
      console.log("create new product");
      productId = await createProduct(newCategoryId, newCategoryName, newHash);
      if (productId) {
        console.log("product created: " + JSON.stringify(productId));
        productId = productId.returning[0].id;
        if (newCharacteristics && newCharacteristics.length > 0) {
          const result = await insertProductCharacteristics(
            productId,
            newCharacteristics
          );
          console.log("created new characteristics " + JSON.stringify(result));
        }
      }
      console.log("created new product " + JSON.stringify(productId));
    }
    if (newCategoryId || newCharacteristics) {
      const result = await update_comp_lookup_record(
        compLookupId,
        newCategoryId,
        newCategoryName,
        productId
      );
      console.log("updated comp lookup record " + JSON.stringify(result));
    }
    setCategoryName(newCategoryName);
    setCategoryId(newCategoryId.toString());
    console.log("new product id: " + productId);
    setProductId(productId);
    setClassifyConfidence(1);
  }
  setShowEdit(false);
}

async function generateHash(characteristics) {
  let qualifier = "";
  let digest = null;

  const sortedKeys = Object.keys(characteristics).sort((a, b) => {
    return a.localeCompare(b);
  });
  sortedKeys.forEach((key) => {
    const value = characteristics[key].toString();
    qualifier += `${key.toLowerCase()}=${value.toLowerCase()}_`;
  });

  if (qualifier.length > 0) {
    digest = SHA1(qualifier);
  }

  return digest;
}

export default EditArea;
