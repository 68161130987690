import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomerDownloadsAPI from "../../api/CustomerDownloadsAPI";
import LoadingSpinner from "../LoadingSpinner";

function Downloads({ setTitle, customer }) {
  const [customerDownloadsData, setCustomerDownloadsData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setTitle(customer + " SSA Downloads");
    setRows([]);
  }, []);

  useEffect(() => {
    console.log(
      "customerdownloaddata " + JSON.stringify(customerDownloadsData)
    );
    if (customerDownloadsData && customerDownloadsData.length > 0) {
      let newDownloads = [];
      for (let download of customerDownloadsData) {
        let newDownload = {};
        newDownload.id = download.updated_at;
        newDownload.store = download.store;
        newDownload.downloads = download.downloads;
        newDownload.updated_at = download.updated_at.substring(0, 10);
        newDownloads.push(newDownload);
      }
      setRows(newDownloads);
      console.log(JSON.stringify(newDownloads));
    } else {
      console.log("No downloads data");
    }
  }, [customerDownloadsData]);

  const columns = [
    {
      field: "store",
      headerName: "Store",
      flex: 0.5,
      headerClassName: "data-grid-header",
    },
    {
      field: "downloads",
      headerName: "Downloads",
      flex: 0.5,
      headerClassName: "data-grid-header",
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 100,
      headerClassName: "data-grid-header",
    },
  ];
  return (
    <div>
      <Typography variant="h5" mb={5}>
        {customer} SSA Downloads
      </Typography>
      <CustomerDownloadsAPI
        setCustomerDownloadsData={setCustomerDownloadsData}
        customer={customer}
      />
      {rows ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .data-grid-header": {
              backgroundColor: "#ffc400",
            },
            "& .valid": {
              backgroundColor: "#4caf50",
            },
            "& .invalid": {
              backgroundColor: "#f44336",
            },
          }}
        >
          <DataGrid
            density="compact"
            autoHeight
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      ) : (
        <Box>
          <LoadingSpinner />
        </Box>
      )}
    </div>
  );
}

export default Downloads;
