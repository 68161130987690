import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import Home from "./components/Home";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

const App = () => {
  const { signIn, signOut, user, isSignedIn } = useAuth({
    provider: "Cognito",
    options: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
      region: process.env.REACT_APP_USER_POOL_REGION,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ["email", "aws.cognito.signin.user.admin", "openid"],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
        region: process.env.REACT_APP_USER_POOL_REGION,
        responseType: "code",
      },
    },
  });

  const SignIn = () => {
    if (!isSignedIn && !user) {
      signIn();
    }
    return null;
  };
  const queryClient = new QueryClient(
    process.env.REACT_APP_CUSTOMER_API_ENDPOINT
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {isSignedIn || user ? (
          <div style={{ whiteSpace: "pre" }}>
            <Home signOut={signOut} />
          </div>
        ) : (
          <SignIn />
        )}
      </Router>
    </QueryClientProvider>
  );
};

export default App;
