import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";

const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

function FailedPriceCollectionAPI({ setFailedCollections }) {
  const { status, data } = usePriceCollectionFailures(endpoint, hasuraSecret);

  useEffect(() => {
    setFailedCollections(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function usePriceCollectionFailures(endpoint, hasuraSecret) {
  let variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  return useQuery(
    ["priceCollectionFailures"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetPriceCollectionFailures {
            collection_failures(
              where: { priceCollectorName: { _is_null: false } }
              distinct_on: id
            ) {
              store
              account
              priceCollectorName
              updated_at
              id
              failures
              errorMessage
              customerId
              logFile
              category
              params
              itemsUrl
              accountName: collection_failures_customer_accounts {
                account_name
              }
            }
          }
        `,
        variables,
        requestHeaders
      );
      console.log(data.collection_failures);
      return data.collection_failures;
    },
    {
      cacheTime: 0,
    }
  );
}

export default FailedPriceCollectionAPI;
