import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { useAliases } from "../../../api/TaxonomyAPI";
import AliasDetail from "./AliasDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    padding: "2px",
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
  },
}));

export default React.memo(function CategoryAliases({ selectedNode }) {
  // const selectedNode = props.selectedNode;
  const classes = useStyles();
  console.log("alias render");

  let key = 0;
  if (selectedNode) {
    key = selectedNode;
  }
  const { status, data, error, isFetching } = useAliases(key);

  return (
    <Box>
      <Typography color="primary">Aliases</Typography>
      <Divider />
      <List
        className={classes.root}
        component="nav"
        disablePadding
        aria-label="secondary mailbox folders"
        dense
      >
        {data ? (
          data.map((value) => {
            return (
              <AliasDetail id={value.id} name={value.name} key={value.id} />
            );
          })
        ) : (
          <></>
        )}
      </List>
    </Box>
  );
});
