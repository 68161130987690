import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ClientList from "./ClientList";

function Clients({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Clients");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ClientList setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default Clients;
