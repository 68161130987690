import React, { useState } from "react";
import { useCategories } from "../../../api/TaxonomyAPI";
import TreeView from "devextreme-react/tree-view";
import CategoryAliases from "./CategoryAliases";
import CategoryCharacteristics from "./CategoryCharacteristics";
import Box from "@mui/material/Box";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Typography from "@mui/material/Typography";

let treeView = null;
const expandLess = () => {
  if (treeView) {
    treeView.component.collapseAll();
    treeView.component.repaint();
  }
};

function Categories() {
  // let treeViewRef = React.createRef();
  const { status, data, error, isFetching } = useCategories();
  const [selectedNode, setSelectedNode] = useState(0);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box display="flex" flexDirection="row">
          <Box display="flex" p={1}>
            <ExpandLessIcon
              onClick={expandLess}
              style={{ cursor: "pointer" }}
            ></ExpandLessIcon>
          </Box>
          <Box display="flex" height="100%" width="50%" p={0}>
            {status === "loading" ? (
              "Loading..."
            ) : status === "error" ? (
              <span>Error: {error.message}</span>
            ) : data ? (
              <TreeView
                dataStructure="plain"
                id="treeview"
                keyExpr="id"
                displayExpr="name"
                parentIdExpr="parentId"
                items={data}
                width="100%"
                searchMode="contains"
                searchEnabled={true}
                searchTimeout={500}
                selectionMode="single"
                selectByClick={true}
                onContentReady={(e) => {
                  treeView = e;
                  setSelectedNode(0);
                }}
                onSelectionChanged={(e) => {
                  if (e.component.getSelectedNodes()) {
                    let nodes = e.component.getSelectedNodes();
                    if (nodes && nodes.length) {
                      setSelectedNode(nodes[0].key);
                      console.log(nodes[0].key);
                    }
                  } else {
                    setSelectedNode(0);
                  }
                }}
              />
            ) : (
              <div>No data - {error}</div>
            )}
          </Box>
          <Box> </Box>

          {selectedNode > 0 ? (
            <Box
              display="flex"
              flexDirection="row"
              border={1}
              mt={5}
              borderRadius="3px"
              borderColor="#4682b4"
            >
              <Box display="flex" p={2}>
                <CategoryAliases selectedNode={selectedNode} />
              </Box>
              <Box display="flex" p={2}>
                <CategoryCharacteristics selectedNode={selectedNode} />
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </div>
    </>
  );
}

export default Categories;
