import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PurchaseHistorySchedulesAPI from "../../api/PurchaseHistorySchedulesAPI";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import { FormControlLabel, IconButton } from "@mui/material";
import { blue } from "@mui/material/colors";

const columns = [
  {
    field: "store",
    headerName: "Store",
    width: 130,
    headerClassName: "data-grid-header",
  },
  {
    field: "account",
    headerName: "Account",
    width: 110,
    headerClassName: "data-grid-header",
  },
  {
    field: "schedule",
    headerName: "Next Run",
    width: 180,
    headerClassName: "data-grid-header",
  },
  {
    field: "password",
    headerName: "pwd",
    headerClassName: "data-grid-header",
    hide: true,
  },
  {
    field: "actions",
    headerName: "Run Now",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <MatEdit row={params.row} />
        </div>
      );
    },
  },
  {
    field: "account_name",
    headerName: "Account Name",
    width: 350,
    headerClassName: "data-grid-header",
  },
  {
    field: "billing_tier",
    headerName: "Billing Tier",
    width: 150,
    headerClassName: "data-grid-header",
  },
];

const MatEdit = ({ row }) => {
  const [message, setMessage] = useState("");
  const handleEditClick = async () => {
    let userid = row.uid;
    let password = encodeURIComponent(row.pwd);
    let store = row.store;
    let account = row.account;
    let account_name = row.account_name;
    let billing_tier = row.billing_tier;
    let customer_id = row.customer_id;
    let scheduledJobsApi =
      process.env.REACT_APP_MANUAL_PRICE_COLLECTOR_ENDPOINT;

    console.log(
      `${scheduledJobsApi}/manualRunPC?userid=${userid}&password=${password}&store=${store}&cid=${customer_id}&account=${account}&category=${category}&params=${params}&itemsUrl=${itemsUrl}`
    );

    try {
      // const response = { status: 200 };
      const response = await fetch(
        `${scheduledJobsApi}/manualRunPC?userid=${userid}&password=${password}&store=${store}&cid=${customer_id}&account=${account}&category=${category}&params=${params}&itemsUrl=${itemsUrl}`
      );

      console.log(response);
      console.log(response.status);
      console.log(response.statusText);

      if (response.status == 200) {
        console.log("good");
        setMessage("Running...");
      } else {
        console.log("bad");
        setMessage(response.statusText);
      }
    } catch (err) {
      console.log(err);
      setMessage(err.message);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={handleEditClick}
          >
            <PlayCircleFilled style={{ color: blue[500] }} />
          </IconButton>
        }
      />
      <>{message}</>
    </>
  );
};

export default function Schedules({ setTitle }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rows, setRows] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);

  useEffect(() => {
    setTitle("Purchase History Collector Schedules");
  }, []);

  useEffect(() => {
    if (scheduleData && scheduleData.length > 0) {
      let newSchedules = [];
      var options = {
        tz: "GMT",
      };
      for (let schedule of scheduleData) {
        let newSchedule = {};
        newSchedule.id = schedule.id;
        newSchedule.account = schedule.account;
        newSchedule.store = schedule.store;
        newSchedule.customer_id = schedule.customer_id;
        newSchedule.uid = schedule.uid;
        newSchedule.pwd = schedule.pwd;
        newSchedule.account_name = schedule.account_name;
        newSchedule.billing_tier = schedule.billing_tier;
        newSchedule.timestamp = schedule.timestamp;

        const firstInt = schedule.id.match(/\d+/g)[0];
        const secondInt = new Date(schedule.timestamp).getTime();
        const thirdInt = schedule.account.match(/.*(\d{4})/)[1];

        const dayMod = +firstInt % 7;
        const hourMod = +secondInt % 24;
        const fiveMinMod = +thirdInt % 12;

        var weekday = new Array(7);
        weekday[0] = "Sunday";
        weekday[1] = "Monday";
        weekday[2] = "Tuesday";
        weekday[3] = "Wednesday";
        weekday[4] = "Thursday";
        weekday[5] = "Friday";
        weekday[6] = "Saturday";

        var fiveMin = new Array(12);
        fiveMin[0] = "00";
        fiveMin[1] = "05";
        fiveMin[2] = "10";
        fiveMin[3] = "15";
        fiveMin[4] = "20";
        fiveMin[5] = "25";
        fiveMin[6] = "30";
        fiveMin[7] = "35";
        fiveMin[8] = "40";
        fiveMin[9] = "45";
        fiveMin[10] = "50";
        fiveMin[11] = "55";

        const twoDigitHour = ("0" + hourMod).slice(-2);

        newSchedule.schedule = `${weekday[dayMod]} ${twoDigitHour}:${fiveMin[fiveMinMod]} GMT`;

        newSchedules.push(newSchedule);
      }
      setRows(newSchedules);
    } else {
      console.log("No schedule data");
    }
  }, [scheduleData]);

  const handleClick = () => {
    navigate("/ph-schedules-manual-run", { state: { selected } });
  };

  const handleSelection = (ids) => {
    if (ids.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    let data = rows.filter((row) => ids.includes(row.id));

    setSelected(data);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Purchase History Collector Schedules
        </Typography>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={handleClick}
          sx={{ display: "none" }}
        >
          Manually Run Selected
        </Button>
      </Box>
      {state !== null && state.success ? (
        <Stack sx={{ width: "100%", margin: "32px 0" }}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The schedules have been updated for the selected items. If you do
            not see the changes reflected immediately try refreshing your page.
          </Alert>
        </Stack>
      ) : null}
      <PurchaseHistorySchedulesAPI setScheduleData={setScheduleData} />
      {rows ? (
        <Box sx={{ width: "2000px", overflowX: "scroll" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            disableSelectionOnClick
            onSelectionModelChange={handleSelection}
            getRowId={(row) =>
              row.store +
              "_" +
              row.account +
              "_" +
              row.customer_id +
              "_" +
              row.category
            }
          />
        </Box>
      ) : null}
    </div>
  );
}
