import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ValueDetail(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    console.log("handleOpen");
  };

  const handleClose = () => {
    setOpen(false);
    console.log("handleClose");
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{props.name}</h2>
      <Button type="button" onClick={handleClose}>
        Close
      </Button>
    </div>
  );

  return (
    <>
      <ListItem dense button key={props.id} onClick={handleOpen}>
        <ListItemText
          primary={props.name}
          key={props.id}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
      </ListItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
}
