import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CharacteristicAliases from "./CharacteristicAliases";
import CharacteristicValues from "./CharacteristicValues";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CharacteristicDetail(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    console.log("handleOpen");
  };

  const handleClose = () => {
    setOpen(false);
    console.log("handleClose");
  };

  const body = (
    <Box style={modalStyle} className={classes.paper}>
      <Typography id="simple-modal-title" variant="h5">
        {props.name}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        // bgcolor="background.paper"
        mt={2}
      >
        <Box className={classes.root} px={2}>
          <Typography color="primary">Aliases</Typography>
          <Divider />
          <List
            component="nav"
            disablePadding
            aria-label="secondary mailbox folders"
          ></List>
        </Box>
        <Box className={classes.root} px={2}>
          <Typography color="primary">Values</Typography>
          <Divider />
          <List
            component="nav"
            disablePadding
            aria-label="secondary mailbox folders"
          ></List>
        </Box>
      </Box>
      <Box mt={5}>
        <Button type="button" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <ListItem dense button key={props.id} onClick={handleOpen}>
        <ListItemText
          primary={props.name}
          key={props.id}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
      </ListItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={modalStyle} className={classes.paper}>
          <Typography id="simple-modal-title" variant="h5">
            {props.name}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            bgcolor="background.paper"
            mt={2}
          >
            <Box className={classes.root} px={2}>
              <CharacteristicAliases
                selectedNode={props.id}
              ></CharacteristicAliases>
            </Box>
            <Box className={classes.root} px={2}>
              <CharacteristicValues
                selectedNode={props.id}
              ></CharacteristicValues>
            </Box>
          </Box>
          <Box mt={5}>
            <Button type="button" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
