import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useCharacteristics } from "../../../api/TaxonomyAPI";
import CharacteristicDetail from "./CharacteristicDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    padding: "2px",
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
  },
}));

export default React.memo(function CategoryCharacteristics({ selectedNode }) {
  const classes = useStyles();
  let key = 0;
  if (selectedNode) {
    key = selectedNode;
  }
  const { status, data, error, isFetching } = useCharacteristics(key);

  return (
    <Box>
      <Typography color="primary">Characteristics</Typography>
      <Divider />
      <List
        className={classes.root}
        component="nav"
        disablePadding
        aria-label="secondary mailbox folders"
      >
        {data ? (
          data.map((value) => {
            return (
              <CharacteristicDetail
                id={value.id}
                name={value.name}
                key={value.id}
              />
            );
          })
        ) : (
          <></>
        )}
      </List>
    </Box>
  );
});
