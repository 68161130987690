import { request, gql } from "graphql-request";
import { useQuery } from "react-query";

const path = window.location.href;
// const endpoint = "http://localhost:8080/v1/graphql";

let endpoint = "https://taxonomy-api.foodmaven.tools/v1/graphql";
if (path.includes("foodmaven.tools")) {
  endpoint = "https://taxonomy-api.foodmaven.tools/v1/graphql";
} else if (path.includes("foodmaven.com")) {
  endpoint = "https://taxonomy-api.foodmaven.com/v1/graphql";
}
let variables = {};
let hasuraSecret = process.env.REACT_APP_HASURA_SECRET;
const requestHeaders = {
  "x-hasura-admin-secret": hasuraSecret,
};

export function useCategories() {
  return useQuery("categories", async () => {
    const { categories: data } = await request(
      endpoint,
      gql`
        query {
          categories {
            id
            name
            parentId
            searchName
            product
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data;
  });
}
export function useAliases(id) {
  console.log(id);
  return useQuery(["aliases", id], async () => {
    const { category_aliases: data } = await request(
      endpoint,
      gql`
                query {
                    category_aliases(where: { categoryId: { _eq: ${id} } }, order_by: {name: asc}) {
                        categoryId
                        id
                        name
                    }
                }
            `,
      variables,
      requestHeaders
    );
    return data;
  });
}
export function useCharacteristicAliases(id) {
  console.log(id);
  return useQuery(["characteristicaliases", id], async () => {
    const { characteristic_aliases: data } = await request(
      endpoint,
      gql`
                query {
                    characteristic_aliases(where: { characteristicId: { _eq: ${id} } }, order_by: {name: asc}) {
                        characteristicId
                        id
                        name
                    }
                }
            `,
      variables,
      requestHeaders
    );
    return data;
  });
}
export function useCharacteristicValues(id) {
  console.log(id);
  return useQuery(["characteristicvalues", id], async () => {
    const { value: data } = await request(
      endpoint,
      gql`
                query {
                    value(where: { characteristicId: { _eq: ${id} } }, order_by: {name: asc}) {
                        characteristicId
                        id
                        name
                    }
                }
            `,
      variables,
      requestHeaders
    );
    return data;
  });
}

export function useCharacteristics(id) {
  console.log(id);
  return useQuery(["characteristics", id], async () => {
    const { characteristic: data } = await request(
      endpoint,
      gql`
                query {
                    characteristic(where: { categoryId: { _eq: ${id} }}, order_by: {name: asc} ) {
                        categoryId
                        id
                        name
                    }
                }
            `,
      variables,
      requestHeaders
    );
    return data;
  });
}

export async function getCategories() {
  const { categories: data } = await request(
    endpoint,
    gql`
      query {
        categories(order_by: { name: asc }) {
          id
          name
          parentId
          searchName
          product
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data;
}
