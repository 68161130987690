import { request, gql } from "graphql-request";

const PRODUCT_API_ENDPOINT = process.env.REACT_APP_PRODUCT_API_ENDPOINT;
const TAXONOMY_SERVICE_ENDPOINT =
  process.env.REACT_APP_TAXONOMY_SERVICE_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

/**
 * Creates a new product with the specified category ID, category name, and characteristic hash.
 *
 * @param {number} newCategoryId - The ID of the new category.
 * @param {string} newCategoryName - The name of the new category.
 * @param {string} newHash - The characteristic hash of the new product.
 * @returns {Promise<Object>} - A promise that resolves to the inserted product object.
 */
export async function createProduct(newCategoryId, newCategoryName, newHash) {
  const catName = newCategoryName;
  const catId = newCategoryId.toString();
  const hash = newHash.toString();
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };
  const variables = {
    categoryId: catId,
    categoryName: catName,
    characteristicHash: hash,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      mutation CreateProduct(
        $categoryName: String!
        $categoryId: String!
        $characteristicHash: String!
      ) {
        insert_product(
          objects: {
            categoryId: $categoryId
            categoryName: $categoryName
            characteristicHash: $characteristicHash
          }
        ) {
          returning {
            id
          }
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.insert_product;
}

/**
 * Inserts product characteristics into the database.
 *
 * @param {string} productId - The ID of the product.
 * @param {Array<Object>} newCharacteristics - An array of new characteristics to be inserted.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of inserted characteristics.
 */
export async function insertProductCharacteristics(
  productId,
  newCharacteristics
) {
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };
  const newChars = [];
  newCharacteristics.forEach((char) => {
    const newChar = {
      productId,
      name: char.name,
      value: char.value,
      type: char.type,
      externalCharacteristicId: char.externalCharacteristicId.toString(),
    };
    newChars.push(newChar);
  });

  const variables = { objects: newChars };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      mutation InsertCharacteristics(
        $objects: [characteristic_insert_input!]!
      ) {
        insert_characteristic(objects: $objects) {
          returning {
            id
          }
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.insert_characteristic;
}

/**
 * Retrieves the characteristics and unit of measure for a given category ID.
 *
 * @param {string} categoryId - The ID of the category.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved data.
 */
export async function getCharacteristicsAndUom(categoryId) {
  const url = `${TAXONOMY_SERVICE_ENDPOINT}/category/${categoryId}/characteristicsAndUom`;
  const response = await fetch(url, {
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  return data;
}

/**
 * Retrieves the product ID based on the provided category ID and hash.
 *
 * @param {number} categoryId - The category ID.
 * @param {string} hash - The characteristic hash.
 * @returns {Promise<number>} - A promise that resolves to the product ID.
 */
export async function getProductId(categoryId, hash) {
  const categoryIdString = categoryId.toString();
  const characteristicHash = hash ? hash.toString() : "";

  const variables = {
    categoryIdString,
    characteristicHash,
  };
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      query GetProductId(
        $categoryIdString: String!
        $characteristicHash: String!
      ) {
        product(
          where: {
            categoryId: { _eq: $categoryIdString }
            _and: { characteristicHash: { _eq: $characteristicHash } }
          }
        ) {
          id
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.product;
}
/**
 * Fetches the characteristics of a product from the database.
 *
 * @param {string} productId - The ID of the product.
 * @returns {Promise} A promise that resolves with the data from the API response. The response data includes the name, value, type, and externalCharacteristicId of each characteristic.
 */
export async function getProductCharacteristics(productId) {
  const variables = { productId };
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      query GetCharacteristics($productId: uuid!) {
        characteristic(where: { productId: { _eq: $productId } }) {
          name
          value
          type
          externalCharacteristicId
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.characteristic;
}

/**
 * Fetches a list of comps from the database.
 *
 * @param {number} offSet - The number of records to skip before starting to fetch the comps.
 * @param {number} limit - The maximum number of comps to return.
 * @returns {Promise} A promise that resolves with the data from the API response.
 */
export async function getComps(offSet, limit) {
  const variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      query GetComps {
        comp_lookup(order_by: { classify_confidence: asc }, limit: ${limit}, offset: ${offSet}) {
          uuid
          store
          storeProductId
          name
          createdAt
          categoryName
		  categoryId
		  productId
		  classify_confidence
		  url
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.comp_lookup;
}
/**
 * Searches for comps based on the provided search phrase.
 *
 * @param {string} search_phrase - The search phrase to use for searching comps.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of comp objects matching the search phrase.
 */
export async function searchComps(search_phrase) {
  const variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
		query SearchComps {
		  search_comp_lookup(args: {search_phrase: "${search_phrase}"}) {
			name
			store
			storeProductId
			categoryName
			classify_confidence
			uuid
			categoryId
			productId
			url
		  }
		}
	  `,
    variables,
    requestHeaders
  );
  return data.search_comp_lookup;
}
/**
 * Updates a record in the comp_lookup table.
 *
 * @param {string} compLookupId - The ID of the record to update.
 * @param {number} categoryId - The new category ID for the record.
 * @param {string} categoryName - The new category name for the record.
 * @param {string} productId - The new product ID for the record. If not provided, defaults to a null UUID.
 * @returns {Promise} A promise that resolves with the data from the API response.
 */
export async function update_comp_lookup_record(
  compLookupId,
  categoryId,
  categoryName,
  productId
) {
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };
  const variables = {
    compLookupId,
    categoryName,
    categoryId,
    productId: productId || "00000000-0000-0000-0000-000000000000",
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      mutation UpdateCompLookup(
        $compLookupId: uuid!
        $categoryName: String!
        $categoryId: Int
        $productId: uuid
      ) {
        update_comp_lookup(
          where: { uuid: { _eq: $compLookupId } }
          _set: {
            categoryName: $categoryName
            categoryId: $categoryId
            productId: $productId
            classify_confidence: 1
          }
        ) {
          returning {
            uuid
          }
        }
      }
    `,
    variables,
    requestHeaders
  );
  return data.update_comp_lookup;
}

/**
 * Deletes a product record from the store.
 *
 * @param {string} store - The name of the store.
 * @param {string} storeProductId - The ID of the product in the store.
 * @param {string} productDescription - The description of the product.
 * @returns {Promise} A promise that resolves with the data from the API response.
 */
export async function delete_comp_records(
  store,
  storeProductId,
  productDescription
) {
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  const variables = {
    store,
    storeProductId,
    productDescription,
  };

  const data = await request(
    PRODUCT_API_ENDPOINT,
    gql`
      mutation DeleteComps(
        $store: String!
        $storeProductId: String!
        $productDescription: String!
      ) {
        delete_comp(
          where: {
            store: { _eq: $store }
            _and: {
              storeProductId: { _eq: $storeProductId }
              _and: { name: { _eq: $productDescription } }
            }
          }
        ) {
          affected_rows
        }
      }
    `,
    variables,
    requestHeaders
  );

  return data.delete_comp;
}
