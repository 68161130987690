import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SupplierFetchAPI from "../../../api/supplier_matching/fetchSuppliers";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import { FormControlLabel, IconButton } from "@mui/material";

const columns = [
  {
    field: "id",
    headerName: "Id",
    headerClassName: "data-grid-header",
    width: 150,
    hide: true,
  },
  {
    field: "supplier_id",
    headerName: "Supplier Id",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "supplier_ar",
    headerName: "Supplier A/R #",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "region",
    headerName: "Region",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "website",
    headerName: "Website",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    width: 140,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <MatEdit index={params.row} />
        </div>
      );
    },
  },
];

const MatEdit = ({ index }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleEditClick = () => {
    navigate("/leaf-training-data", {
      state: {
        categoryid: index.categoryId,
        categoryname: index.categoryName,
      },
    });
  };

  return (
    <FormControlLabel
      control={
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={handleEditClick}
        >
          <EditIcon style={{ color: blue[500] }} />
        </IconButton>
      }
    />
  );
};

export default function SupplierList({ setTitle }) {
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "count",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    setTitle("Suppliers");
  }, []);

  const handleSelection = (ids) => {
    if (ids.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    let data = suppliers.filter((row) => ids.includes(row.id));

    setSelected(data);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Supplier List
        </Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <SupplierFetchAPI setSuppliers={setSuppliers} />
        {suppliers ? (
          <DataGrid
            rows={suppliers}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            disableSelectionOnClick
            onSelectionModelChange={handleSelection}
            getRowId={(row) => row.id}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        ) : null}
      </Box>
    </div>
  );
}
