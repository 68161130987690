import { useEffect } from "react";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";
import { Navigate } from "react-router-dom";

function CustomerDownloadsAPI({ setCustomerDownloadsData, customer }) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
  const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

  console.log("calling customer downloads api " + customer);

  const { status, data } = useCustomerDownloads(
    endpoint,
    hasuraSecret,
    customer
  );

  useEffect(() => {
    setCustomerDownloadsData(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useCustomerDownloads(endpoint, hasuraSecret, customer) {
  let variables = {};
  if (customer) {
    variables = { customer: customer };
  }
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };
  console.log("in customer download hook " + customer);

  if (customer) {
    return useQuery(
      ["customerDownloadsCustomer"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getDownloads($customer: String!) {
              ssa_downloads(
                where: { customer_group: { _eq: $customer } }
                order_by: { updated_at: desc }
              ) {
                id
                store
                downloads
                customer_group
                updated_at
              }
            }
          `,
          variables,
          requestHeaders
        );
        console.log(data.ssa_downloads);
        return data.ssa_downloads;
      },
      {
        cacheTime: 0,
      }
    );
  } else {
    return useQuery(
      ["customerDownloadsFoodMaven"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getDownloads {
              ssa_downloads(
                where: { customer_group: { _eq: "" } }
                order_by: { updated_at: desc }
              ) {
                id
                store
                downloads
                customer_group
                updated_at
              }
            }
          `,
          variables,
          requestHeaders
        );
        console.log(data.ssa_downloads);
        return data.ssa_downloads;
      },
      {
        cacheTime: 0,
      }
    );
  }
}
export default CustomerDownloadsAPI;
