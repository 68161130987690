import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import CategoryList from "./CategoryList";

function Categories({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Categories");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CategoryList setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default Categories;
