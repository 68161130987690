import { GraphQLClient, gql } from "graphql-request";

const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

const client = new GraphQLClient(endpoint, {
  headers: { "x-hasura-admin-secret": hasuraSecret },
});

export default async function updatePriceCollectorSchedule(
  priceCollectorId,
  schedule
) {
  const variables = {
    id: priceCollectorId,
    schedule: schedule,
  };

  const mutation = gql`
    mutation UpdateSchedule($id: uuid!, $schedule: String!) {
      update_price_collector_schedule(
        where: { id: { _eq: $id } }
        _set: { schedule: $schedule }
      ) {
        affected_rows
        returning {
          id
          priceCollectorName
          category
          schedule
          customer_store {
            customer {
              email
            }
          }
        }
      }
    }
  `;

  await client.request(mutation, variables);
}
