import { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AWS from "aws-sdk";
import deleteCustomerAccount from "../../api/DeleteCustomerAccountAPI";
import LoadingSpinner from "../LoadingSpinner";

const columns = [
  {
    field: "createDate",
    headerName: "Sign Up Date",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Last name",
    headerClassName: "data-grid-header",
    width: 120,
  },
  {
    field: "firstName",
    headerName: "First name",
    headerClassName: "data-grid-header",
    width: 120,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "data-grid-header",
    minWidth: 200,
    flex: 0.5,
  },
  //{
  //  field: "company",
  //  headerName: "Company",
  //  headerClassName: "data-grid-header",
  //  minWidth: 200,
  //  flex: 0.5,
  //},
  //{
  //  field: "zip",
  //  headerName: "Zip",
  //  headerClassName: "data-grid-header",
  //  minWidth: 50,
  //  flex: 0.5,
  //},
  {
    field: "enabled",
    headerName: "Enabled",
    headerClassName: "data-grid-header",
    width: 100,
  },
  //{
  //  field: "entegraId",
  //  headerName: "Customer Id",
  //  headerClassName: "data-grid-header",
  //  width: 100,
  //},
];

export default function Users({ setTitle, customer }) {
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(false);

  let userPoolId = process.env.REACT_APP_USER_POOL_ID;

  if (customer === "Entegra") {
    userPoolId = process.env.REACT_APP_ENTEGRA_USER_POOL_ID;
  }

  const getUsers = async () => {
    try {
      let allUsers = [];
      let more = true;
      let paginationToken = "";

      while (more) {
        let params = {
          UserPoolId: userPoolId,
          Limit: 60,
        };
        if (paginationToken !== "") {
          params.PaginationToken = paginationToken;
        }

        AWS.config.update({
          region: process.env.REACT_APP_USER_POOL_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_KEY,
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const rawUsers = await cognito.listUsers(params).promise();
        allUsers = allUsers.concat(rawUsers.Users);
        if (rawUsers.PaginationToken) {
          paginationToken = rawUsers.PaginationToken;
        } else {
          more = false;
        }
      }
      setUsers(allUsers);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setRows([]);
    setTitle(customer + " User Signups");
    getUsers();
  }, [customer]);

  useEffect(() => {
    let newUsers = [];
    for (let user of users) {
      let newUser = {};
      newUser.id = user.Username;
      newUser.userStatus = user.UserStatus;
      newUser.enabled = user.Enabled;
      newUser.createDate = user.UserCreateDate.toISOString().split("T")[0];
      let attributes = user.Attributes;
      for (let attribute of attributes) {
        if (attribute.Name === "given_name") {
          newUser.firstName = attribute.Value;
        } else if (attribute.Name === "family_name") {
          newUser.lastName = attribute.Value;
          // } else if (attribute.Name === "custom:company") {
          //   newUser.company = attribute.Value;
          // } else if (attribute.Name === "custom:zip") {
          //   newUser.zip = attribute.Value;
          // } else if (attribute.Name === "custom:entegra_id") {
          //   newUser.entegraId = attribute.Value;
        } else if (attribute.Name === "email_verified") {
          newUser.emailVerified = attribute.Value;
        } else if (attribute.Name === "email") {
          newUser.email = attribute.Value;
        }
      }
      newUsers.push(newUser);
    }
    newUsers = newUsers.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
    setRows(newUsers);
  }, [users]);

  const handleDelete = async () => {
    selected.forEach(async (user) => {
      await deleteCustomerAccount(user.id, user.email, customer);
    });

    setSuccess(true);
  };

  const handleSelection = (ids) => {
    if (ids.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    let data = rows.filter((row) => ids.includes(row.id));

    setSelected(data);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h5" mb={5}>
          {customer} User Signups via Customer Portal
        </Typography>
        <Button disabled={disabled} variant="contained" onClick={handleDelete}>
          Delete selected users
        </Button>
      </Box>
      {success ? (
        <Stack sx={{ width: "100%", margin: "32px 0" }}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The selected customers have been deleted. If you do not see the
            changes reflected immediately try refreshing your page.
          </Alert>
        </Stack>
      ) : null}
      {rows && rows.length > 1 ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelection}
          />
        </Box>
      ) : (
        <Box>
          <LoadingSpinner />
        </Box>
      )}
    </div>
  );
}
