import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import LocationList from "./LocationList";

function Locations({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Locations");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <LocationList setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default Locations;
