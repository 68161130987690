import { Typography } from "@mui/material";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import TrainingData from "./TrainingData";

function ML({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Training Data");
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <TrainingData setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default ML;
