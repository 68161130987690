import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FailedPurchaseHistoryAPI from "../../api/FailedPurchaseHistoryAPI";

const columns = [
  {
    field: "id",
    headerName: "id",
    headerClassName: "data-grid-header",
    width: 250,
    hide: true,
  },
  {
    field: "updated_at",
    headerName: "Updated",
    headerClassName: "data-grid-header",
    width: 250,
    valueFormatter: (params) => new Date(params?.value).toLocaleString("sv-SE"),
  },
  {
    field: "accountName",
    headerName: "Account Name",
    headerClassName: "data-grid-header",
    width: 250,
    valueGetter: (params) => params.row.accountName?.account_name,
  },
  {
    field: "account",
    headerName: "Account Number",
    headerClassName: "data-grid-header",
    width: 150,
  },
  {
    field: "customerId",
    headerName: "Customer ID",
    headerClassName: "data-grid-header",
    width: 300,
  },
  {
    field: "store",
    headerName: "Store",
    headerClassName: "data-grid-header",
    width: 100,
  },
  {
    field: "failures",
    headerName: "Failures",
    headerClassName: "data-grid-header",
    width: 80,
  },
  {
    field: "errorMessage",
    headerName: "Error Message",
    headerClassName: "data-grid-header",
    width: 300,
  },
  {
    field: "logFile",
    headerName: "Log File",
    headerClassName: "data-grid-header",
    width: 100,
    renderCell: (params) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <a href={params.row.logFile} target="_blank" rel="noreferrer">
            Log
          </a>
        </div>
      );
    },
  },
];

export default function FailedPHC({ setTitle }) {
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [failedCollections, setFailedCollections] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "count",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    setTitle("Failed Purchase History Collections");
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Failed Purchase History Collections
        </Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <FailedPurchaseHistoryAPI setFailedCollections={setFailedCollections} />
        {failedCollections ? (
          <DataGrid
            rows={failedCollections}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            getRowId={(row) => row.id}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        ) : null}
      </Box>
    </div>
  );
}
