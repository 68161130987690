import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Navigate } from "react-router-dom";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";

const endpoint = process.env.REACT_APP_PRODUCT_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;
const requestHeaders = {
  "x-hasura-admin-secret": hasuraSecret,
};

export function LeafTrainingDataAPI({ categoryid, setLeafTrainingData }) {
  console.log("category id " + categoryid);
  const { status, data } = useLeafTrainingData(categoryid);

  useEffect(() => {
    setLeafTrainingData(data);
  }, [data]);

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

export function useUpdateLeafTrainingData() {
  const queryClient = useQueryClient();
  return useMutation(
    async (selected) => {
      let variables = { ids: selected };
      const data = await request(
        endpoint,
        gql`
          mutation UpdateLeafTrainingData($ids: [uuid!]) {
            update_training_data_raw(
              where: { id: { _in: $ids } }
              _set: { misclassified: true }
            ) {
              returning {
                id
              }
            }
          }
        `,
        variables,
        requestHeaders
      );
      return data.update_training_data_raw;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("leafTrainingData");
      },
    }
  );
}

function useLeafTrainingData(categoryid) {
  let variables = { categoryid: categoryid };

  console.log("getting data for " + categoryid);

  return useQuery(
    ["leafTrainingData"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetLeafTrainingData($categoryid: String!) {
            training_data_raw(
              where: {
                categoryId: { _eq: $categoryid }
                _and: { misclassified: { _neq: true } }
              }
              order_by: { score: asc }
            ) {
              id
              compName
              store
              storeProductId
              score
            }
          }
        `,
        variables,
        requestHeaders
      );
      return data.training_data_raw;
    },
    {
      cacheTime: 0,
    }
  );
}
// export default LeafTrainingDataAPI;
