import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";

const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

function PurchaseHistoryAPI({ setPurchaseHistoryAccounts }) {
  const { status, data } = usePurchaseHistoryAccounts(endpoint, hasuraSecret);

  useEffect(() => {
    setPurchaseHistoryAccounts(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function usePurchaseHistoryAccounts(endpoint, hasuraSecret) {
  let variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  return useQuery(
    ["purchaseHistoyAccounts"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetPurchaseHistoryAccounts {
            account_ph_collector_schedule(order_by: { account: asc }) {
              id
              account
              store
              account_name
              uid
              pwd
              customer_id
            }
          }
        `,
        variables,
        requestHeaders
      );
      console.log(data.account_ph_collector_schedule);
      return data.account_ph_collector_schedule;
    },
    {
      cacheTime: 0,
    }
  );
}

export default PurchaseHistoryAPI;
