import { GraphQLClient, gql } from "graphql-request";
import AWS from "aws-sdk";

/*
 * Fully removing a customer account involves a couple different steps
 * hence all the API calls in this function.
 *
 *   1. Delete all the price collectors for the customer
 *   2. Delete the customers_stores records
 *   3. Delete the customer_accounts records
 *   4. Delete the customer record
 *   5. Remove the customer from Cognito
 */

const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

const client = new GraphQLClient(endpoint, {
  headers: { "x-hasura-admin-secret": hasuraSecret },
});

AWS.config.update({
  region: process.env.REACT_APP_USER_POOL_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

const cognito = new AWS.CognitoIdentityServiceProvider();

export default async function deleteCustomerAccount(id, email, customerGroup = null) {
  let userPoolId = process.env.REACT_APP_USER_POOL_ID;

  if (customerGroup === "Entegra") {
    userPoolId = process.env.REACT_APP_ENTEGRA_USER_POOL_ID;
  }
  const variables = {
    email: email,
  };

  const deletePriceCollectors = gql`
    mutation deletePriceCollectors($email: String!) {
      delete_price_collector_schedule(
        where: { customer_store: { customer: { email: { _eq: $email } } } }
      ) {
        affected_rows
        returning {
          id
          schedule
          priceCollectorName
        }
      }
    }
  `;

  const deleteCustomerStores = gql`
    mutation deleteCustomerStores($email: String!) {
      delete_customer_stores(where: { customer: { email: { _eq: $email } } }) {
        affected_rows
        returning {
          id
          store
          customer {
            id
            email
          }
        }
      }
    }
  `;

  const deleteCustomerAccounts = gql`
    mutation deleteCustomerAccounts($email: String!) {
     delete_customer_accounts(where: { customer: { email: { _eq: $email } } }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;

  const deleteCustomer = gql`
    mutation deleteCustomer($email: String!) {
      delete_customer(where: { email: { _eq: $email } }) {
        affected_rows
        returning {
          id
          email
        }
      }
    }
  `;

  return client
    .request(deletePriceCollectors, variables)
    .then(() => {
      return client.request(deleteCustomerStores, variables);
    })
    .then(() => {
      return client.request(deleteCustomerAccounts, variables);
    })
    .then(() => {
      return client.request(deleteCustomer, variables);
    })
    .then(() => {
      return cognito
        .adminDeleteUser({
          UserPoolId: userPoolId,
          Username: id,
        })
        .promise();
    })
    .catch((error) => {
      console.error(error)
      return error;
    });
}
