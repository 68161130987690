import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PurchaseHistoryAPI from "../../api/PurchaseHistoryAPI";
import { blue } from "@mui/material/colors";
import { FormControlLabel, IconButton } from "@mui/material";
import { Box, Typography } from "@mui/material";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";

const CryptoJS = require("crypto-js");
const enckey = process.env.REACT_APP_ENCRYPT_KEY;

const columns = [
  {
    field: "store",
    headerName: "Store",
    headerClassName: "data-grid-header",
    width: 250,
  },
  {
    field: "account",
    headerName: "Account",
    headerClassName: "data-grid-header",
    width: 250,
  },
  {
    field: "account_name",
    headerName: "Account Name",
    headerClassName: "data-grid-header",
    width: 300,
  },
  {
    field: "customer_id",
    headerName: "Customer ID",
    headerClassName: "data-grid-header",
    width: 300,
    hide: true,
  },
  {
    field: "uid",
    headerName: "Uid",
    headerClassName: "data-grid-header",
    width: 100,
    hide: true,
  },
  {
    field: "pwd",
    headerName: "Pwd",
    headerClassName: "data-grid-header",
    width: 100,
    hide: true,
  },
  {
    field: "actions",
    headerName: "Run",
    sortable: false,
    width: 400,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <MatEdit row={params.row} />
        </div>
      );
    },
  },
];

const MatEdit = ({ row }) => {
  const [message, setMessage] = useState("");
  const handleEditClick = async () => {
    let userid = CryptoJS.AES.decrypt(row.uid, enckey).toString(
      CryptoJS.enc.Utf8
    );
    let password = CryptoJS.AES.decrypt(row.pwd, enckey).toString(
      CryptoJS.enc.Utf8
    );
    let encodedPassword = encodeURIComponent(password);
    let store = row.store;
    let customer_id = row.customer_id;
    let account = row.account;
    let scheduledJobsApi = process.env.REACT_APP_SCHEDULED_JOBS_API_ENDPOINT;

    console.log(
      `${scheduledJobsApi}/manualRunPHC?userid=${userid}&password=${encodedPassword}&store=${store}&cid=${customer_id}&account=${account}`
    );

    try {
      const response = await fetch(
        `${scheduledJobsApi}/manualRunPHC?userid=${userid}&password=${encodedPassword}&store=${store}&cid=${customer_id}&account=${account}`
      );

      console.log(response);
      console.log(response.status);
      console.log(response.statusText);

      if (response.status == 200) {
        console.log("good");
        setMessage("Running...");
      } else {
        console.log("bad");
        setMessage(response.statusText);
      }
    } catch (err) {
      console.log(err);
      setMessage(err.message);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={handleEditClick}
          >
            <PlayCircleFilled style={{ color: blue[500] }} />
          </IconButton>
        }
      />
      <>{message}</>
    </>
  );
};

export default function PurchaseHistory({ setTitle }) {
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [purchaseHistoryAccounts, setPurchaseHistoryAccounts] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "account",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    setTitle("Purchase History");
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Manually Run Purchase History Collector
        </Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <PurchaseHistoryAPI
          setPurchaseHistoryAccounts={setPurchaseHistoryAccounts}
        />
        {purchaseHistoryAccounts ? (
          <DataGrid
            rows={purchaseHistoryAccounts}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            disableSelectionOnClick
            getRowId={(row) => row.id}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        ) : null}
      </Box>
    </div>
  );
}
