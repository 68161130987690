import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomerRegistrationAPI from "../../api/CustomerRegistrationAPI";

function Registrations({ setTitle, customer }) {
  const [customerRegistrationData, setCustomerRegistrationData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setTitle(customer + " SSA Installed and Registered");
  }, []);

  useEffect(() => {
    console.log(
      "customerregistrationdata " + JSON.stringify(customerRegistrationData)
    );
    if (customerRegistrationData && customerRegistrationData.length > 0) {
      let newRegs = [];
      for (let reg of customerRegistrationData) {
        let newReg = {};
        newReg.id = reg.updated_at;
        newReg.uid = reg.uid;
        newReg.cid = reg.cid;
        newReg.activity = reg.activity;
        newReg.browserType = reg.browserType;
        newReg.updated_at = reg.updated_at.substring(0, 10);
        newRegs.push(newReg);
      }
      setRows(newRegs);
      console.log(JSON.stringify(newRegs));
    } else {
      console.log("No registration data");
    }
  }, [customerRegistrationData]);

  const columns = [
    {
      field: "uid",
      headerName: "Email",
      headerClassName: "data-grid-header",
      width: 300,
    },
    {
      field: "cid",
      headerName: "Customer Id",
      flex: 0.5,
      headerClassName: "data-grid-header",
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 0.5,
      headerClassName: "data-grid-header",
      cellStyle: { color: "red", "background-color": "green" },
    },
    {
      field: "browserType",
      headerName: "Browser",
      flex: 0.5,
      headerClassName: "data-grid-header",
      cellStyle: { color: "red", "background-color": "green" },
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 100,
      headerClassName: "data-grid-header",
    },
  ];
  return (
    <div>
      <Typography variant="h5" mb={5}>
        {customer} SSA Installed and Registered
      </Typography>
      <CustomerRegistrationAPI
        setCustomerRegistrationData={setCustomerRegistrationData}
        customer={customer}
      />
      {rows ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .data-grid-header": {
              backgroundColor: "#ffc400",
            },
            "& .valid": {
              backgroundColor: "#4caf50",
            },
            "& .invalid": {
              backgroundColor: "#f44336",
            },
          }}
        >
          <DataGrid
            density="compact"
            autoHeight
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      ) : null}
    </div>
  );
}

export default Registrations;
