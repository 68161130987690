import { Typography, Box, Grid, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomerStoresAPI from "../../api/CustomerStoresAPI";
const CryptoJS = require("crypto-js");
const enckey = process.env.REACT_APP_ENCRYPT_KEY;

function CredentialsDetail({ setTitle, customer }) {
  const [customerStoreData, setCustomerStoreData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setTitle(customer + " SSA Credentials");
  }, []);

  useEffect(() => {
    if (customerStoreData && customerStoreData.length > 0) {
      let newCreds = [];
      for (let cred of customerStoreData) {
        let newCred = {};
        newCred.id = cred.id;
        newCred.email = cred.customer.email;
        newCred.store = cred.store;
        newCred.userid = CryptoJS.AES.decrypt(cred.uid, enckey).toString(
          CryptoJS.enc.Utf8
        );
        newCred.status = cred.status;
        newCred.password = CryptoJS.AES.decrypt(cred.pwd, enckey).toString(
          CryptoJS.enc.Utf8
        );
        newCred.updated_at = cred.updated_at.substring(0, 10);
        newCreds.push(newCred);
      }
      setRows(newCreds);
    } else {
      console.log("No customer store data");
    }
  }, [customerStoreData]);

  const columns = [
    {
      field: "email",
      headerName: "Customer",
      headerClassName: "data-grid-header",
      width: 300,
    },
    {
      field: "store",
      headerName: "Store",
      width: 150,
      headerClassName: "data-grid-header",
    },
    {
      field: "userid",
      headerName: "Store Userid",
      flex: 0.5,
      headerClassName: "data-grid-header",
      cellStyle: { color: "red", "background-color": "green" },
    },
    {
      field: "password",
      headerName: "Password",
      width: 150,
      headerClassName: "data-grid-header",
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 100,
      headerClassName: "data-grid-header",
    },
  ];
  return (
    <div>
      <Grid container direction="row">
        <Grid item>
          <Typography variant="h5" mb={5}>
            {customer} Store Credentials Provided
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <CustomerStoresAPI
        setCustomerStoreData={setCustomerStoreData}
        customer={customer}
      />
      {rows ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .data-grid-header": {
              backgroundColor: "#ffc400",
            },
            "& .valid": {
              backgroundColor: "#4caf50",
            },
            "& .invalid": {
              backgroundColor: "#f44336",
            },
            "& .locked": {
              backgroundColor: "#ffa500",
            },
          }}
        >
          <DataGrid
            density="compact"
            autoHeight
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      ) : null}
    </div>
  );
}

export default CredentialsDetail;
