import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Search from "./Search";

function CompAdmin({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Comp Admin");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Search />
    </QueryClientProvider>
  );
}

export default CompAdmin;
