import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CategoryName from "./CategoryName";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getCharacteristicsAndUom,
  getProductCharacteristics,
} from "../../../api/CompAdminAPI";
var SHA1 = require("crypto-js/sha1");

function Characteristics({ categoryId, productId, setNewCharacteristics }) {
  const [characteristics, setCharacteristics] = useState();
  const [uom, setUom] = useState();
  const [productCharacteristics, setProductCharacteristics] = useState(null);
  const [defaultCharacteristics, setDefaultCharacteristics] = useState([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);

  useEffect(async () => {
    if (productCharacteristics) {
      const defaultChars = await getDefaultChars(
        productCharacteristics,
        characteristics
      );

      setDefaultCharacteristics(defaultChars);
    }
  }, [productCharacteristics]);

  useEffect(async () => {
    if (defaultCharacteristics && characteristics) {
      setNewCharacteristics(defaultCharacteristics);
      setShowAutoComplete(true);
    }
    console.log(
      "characteristics defaultCharacteristics: " +
        JSON.stringify(defaultCharacteristics)
    );
  }, [defaultCharacteristics]);

  useEffect(async () => {
    const characteristicsAndUom = await getCharacteristicsAndUom(categoryId);
    setUom(characteristicsAndUom.uom);
    const implicitChars = await getChars(
      characteristicsAndUom.characteristics.implicit
    );
    const scaledChars = await getChars(
      characteristicsAndUom.characteristics.scaled
    );
    const singleGroupChars = await getChars(
      characteristicsAndUom.characteristics.singleGroup
    );
    const multiGroupChars = await getChars(
      characteristicsAndUom.characteristics.multiGroup
    );

    const allChars = implicitChars.concat(
      scaledChars,
      singleGroupChars,
      multiGroupChars
    );
    let uniqueChars = allChars.filter(
      (v, i, a) =>
        a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
    );

    setCharacteristics(uniqueChars);

    const prodChars = await getProductCharacteristics(productId);
    console.log(" characteristics for productId: " + productId);
    console.log(JSON.stringify(prodChars));
    setProductCharacteristics(prodChars);
  }, [productId]);

  const saveNewCharacteristics = async (object, value) => {
    const newChars = value;
    console.log("save newCharacteristics: " + newChars);
    let uniqueChars = newChars.filter(
      (v, i, a) =>
        a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
    );
    setNewCharacteristics(uniqueChars);
    let newhash = "";
    if (uniqueChars && uniqueChars.length > 0) {
      newhash = await generateHash(uniqueChars);
    }
    console.log("new hash: " + newhash);
  };

  return (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      {showAutoComplete && (
        <Autocomplete
          multiple
          id="tags-standard"
          options={characteristics}
          getOptionLabel={(option) => option.displayName}
          defaultValue={defaultCharacteristics}
          onChange={saveNewCharacteristics}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Characteristics"
              placeholder="Add Characteristics"
            />
          )}
        />
      )}
    </Box>
  );
}

async function getChars(implicit) {
  let implicitChars = [];
  implicit.map((char) => {
    const name = char.name;
    char.values.map((value) => {
      const charObj = {
        name: name,
        value: value.name,
        type: value.type,
        externalCharacteristicId: value.characteristicId,
        displayName:
          value.type === "implicit" ? name : name + ": " + value.name,
      };
      implicitChars.push(charObj);
    });
  });
  return implicitChars;
}

async function getDefaultChars(productChars, characteristics) {
  let defaultChars = [];

  if (characteristics && characteristics.length > 0) {
    characteristics.map((char) => {
      productChars.map((prodChar) => {
        const displayName =
          prodChar.type === "implicit"
            ? prodChar.name
            : prodChar.name + ": " + prodChar.value;

        if (char.displayName === displayName) {
          defaultChars.push(char);
        }
      });
    });

    let uniqueChars = defaultChars.filter(
      (v, i, a) =>
        a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
    );

    console.log("defaultChars " + JSON.stringify(uniqueChars));

    return uniqueChars;
  } else {
    return [];
  }
}
async function generateHash(characteristics) {
  let qualifier = "";
  let digest = null;

  const sortedKeys = Object.keys(characteristics).sort((a, b) => {
    return a.localeCompare(b);
  });
  sortedKeys.forEach((key) => {
    const value = characteristics[key].toString();
    qualifier += `${key.toLowerCase()}=${value.toLowerCase()}_`;
  });

  if (qualifier.length > 0) {
    digest = SHA1(qualifier);
  }

  return digest;
}

export default Characteristics;
