import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";

const endpoint = process.env.REACT_APP_PRODUCT_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

function TrainingDataAPI({ setTrainingDataCounts }) {
  const { status, data } = useTrainingDataCounts(endpoint, hasuraSecret);

  useEffect(() => {
    setTrainingDataCounts(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useLeafTrainingData(endpoint, hasuraSecret) {
  let variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  return useQuery(
    ["trainingDataCounts"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetTrainingDataCounts {
            training_data_counts(where: { order_by: { count: desc } }) {
              categoryId
              categoryName
              count
            }
          }
        `,
        variables,
        requestHeaders
      );
      console.log(data.training_data_counts);
      return data.training_data_counts;
    },
    {
      cacheTime: 0,
    }
  );
}

function useTrainingDataCounts(endpoint, hasuraSecret) {
  let variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  return useQuery(
    ["trainingDataCounts"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetTrainingDataCounts {
            training_data_counts(order_by: { count: desc }) {
              categoryId
              categoryName
              count
            }
          }
        `,
        variables,
        requestHeaders
      );
      console.log(data.training_data_counts);
      return data.training_data_counts;
    },
    {
      cacheTime: 0,
    }
  );
}
export default TrainingDataAPI;
