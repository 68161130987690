import { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Tooltip, Link } from "@mui/material";
import CategoryName from "./CategoryName";
import EditArea from "./EditArea";
import { useState } from "react";
import { LaunchIcon } from "@mui/icons-material";

function SearchItem({ comp, index, categories }) {
  const [showEdit, setShowEdit] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [uuid, setUuid] = useState();
  const [productId, setProductId] = useState();
  const [classifyConfidence, setClassifyConfidence] = useState();

  useEffect(async () => {
    setCategoryName(comp.categoryName);
    setCategoryId(comp.categoryId);
    setUuid(comp.uuid);
    setProductId(comp.productId);
    setClassifyConfidence(comp.classify_confidence);
  }, [comp]);

  useEffect(async () => {
    console.log("catName : " + categoryName);
    console.log("catId : " + categoryId);
    console.log("uuid : " + uuid);
    console.log("productId : " + productId);
    console.log("classifyConfidence : " + classifyConfidence);
    // setCategoryName(categoryName);
    // setCategoryId(categoryId);
    // setUuid(uuid);
    // setProductId(productId);
    // setClassifyConfidence(classifyConfidence);
  }, [categoryName, categoryId, uuid, productId, classifyConfidence]);

  return (
    <Box>
      <Box
        key={index}
        sx={{
          display: "flex",
          borderTop: 1,
          padding: 1,
          "&:hover": {
            backgroundColor: "#fff",
            color: "#3c52b2",
            cursor: "pointer",
          },
        }}
        onClick={() => setShowEdit(!showEdit)}
      >
        <Box
          sx={{
            width: "475px",
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <Tooltip title={comp.name}>
            <Typography sx={{ wordBreak: "break-word" }}>
              {comp.name}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          {comp.store?.toUpperCase()}
        </Box>
        <Box
          sx={{
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {comp.storeProductId}
        </Box>
        <Box
          sx={{
            width: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {categoryName}
        </Box>
        <Box
          sx={{
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {classifyConfidence}
        </Box>
        <Box
          sx={{
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {comp.url && (
            <Link href={comp.url} target="_blank">
              Source
            </Link>
          )}
        </Box>
      </Box>
      {showEdit && (
        <EditArea
          categories={categories}
          categoryId={categoryId}
          categoryName={categoryName}
          uuid={uuid}
          productId={productId}
          setShowEdit={setShowEdit}
          setCategoryId={setCategoryId}
          setCategoryName={setCategoryName}
          setProductId={setProductId}
          setClassifyConfidence={setClassifyConfidence}
        />
      )}
    </Box>
  );
}

export default SearchItem;
