import { Typography } from "@mui/material";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Categories from "./Categories";

function Taxonomy({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Food Taxonomy");
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Categories />
    </QueryClientProvider>
  );
}

export default Taxonomy;
