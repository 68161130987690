import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Stack, Typography, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import updatePriceCollectorSchedule from "../../api/UpdatePriceCollectorScheduleAPI";

const columns = [
  {
    field: "priceCollectorName",
    headerName: "Store",
    width: 200,
    headerClassName: "data-grid-header",
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    headerClassName: "data-grid-header",
  },
  {
    field: "email",
    headerName: "Customer",
    width: 300,
    headerClassName: "data-grid-header",
  },
];

const parseDay = (day) => {
  switch (day) {
    case 0:
      return "SUN";
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    case 6:
      return "SAT";
  }
};

const sortCustomerStores = (selected) => {
  const customerStores = selected.map((item) => {
    return { customer: item.email, store: item.priceCollectorName };
  });

  const uniq = customerStores.filter((customerStore, index, array) => {
    return (
      array.findIndex(
        (t) =>
          t.customer == customerStore.customer && t.store == customerStore.store
      ) == index
    );
  });

  return uniq.map((customerStore) =>
    selected.filter(
      (item) =>
        item.email === customerStore.customer &&
        item.priceCollectorName === customerStore.store
    )
  );
};

const timePlusThiryMinutes = (dateTime) => {
  return new Date(dateTime.setMinutes(dateTime.getMinutes() + 30));
};

const formatCron = (dateTime) => {
  let day = parseDay(dateTime.getUTCDay());
  let hours = dateTime.getUTCHours();
  let minutes = dateTime.getMinutes();

  return `${minutes} ${hours} * * ${day}`;
};

export default function ManualRun({ setTitle }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    setTitle("Manually Run Price Collectors");
  }, []);

  const goBack = () => {
    navigate("/pc-schedules");
  };

  const schedulePriceCollectors = () => {
    const customerStores = sortCustomerStores(state.selected);

    customerStores.forEach((array) => {
      let dt = new Date(dateTime);

      array.forEach((object, index) => {
        let cron;

        if (index === 0) {
          cron = formatCron(dt);
        } else {
          timePlusThiryMinutes(dt);
          cron = formatCron(dt);
        }

        object.schedule = cron;

        updatePriceCollectorSchedule(object.id, object.schedule).catch(
          (error) => {
            console.error(JSON.stringify(error, undefined, 2));
          }
        );
      });
    });

    navigate("/pc-schedules", { state: { success: true } });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Manually Run Price Collectors
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={goBack}
            sx={{ marginRight: "16px" }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={schedulePriceCollectors}>
            Schedule
          </Button>
        </Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={4} sx={{ width: "25%" }}>
          <DateTimePicker
            label="Date & Time picker"
            minDate={new Date()}
            value={dateTime}
            onChange={(newDateTime) => setDateTime(newDateTime)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
      <Box sx={{ margin: "32px 0", width: "75%" }}>
        <DataGrid
          rows={state.selected}
          columns={columns}
          autoHeight
          density="compact"
        />
      </Box>
    </>
  );
}
