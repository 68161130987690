import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TextField, Box, Button } from "@mui/material";
import { getComps, searchComps } from "../../../api/CompAdminAPI";
import { getCategories } from "../../../api/TaxonomyAPI";
import SearchItem from "./SearchItem";

function Search() {
  const [items, setItems] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(async () => {
    setItems(await getComps(0, 1000));
    setOffSet(100);
    let cats = await getCategories();
    let catList = [];

    cats.forEach((cat) => {
      if (cat.product === true) {
        catList.push({ label: cat.name?.toUpperCase(), value: cat.id });
      }
    });
    setCategories(catList);
  }, []);

  const fetchMoreData = async () => {
    setItems(items.concat(await getComps(offSet, 1000)));
    setOffSet(offSet + 100);
    console.log("fetchMoreData");
  };
  const debouncedResults = async (e) => {
    if (e.target.value.length < 1) {
      setItems([]);
      setItems(await getComps(0, 1000));
    } else {
      console.log("debouncedResults");
      console.log(e.target.value);
      setItems(await searchComps(e.target.value));
    }
  };

  return (
    <>
      <h1>Comps</h1>
      <Box paddingBottom={2} paddingTop={1}>
        <TextField
          variant="outlined"
          placeholder="Search"
          fullWidth
          onChange={debouncedResults}
          name="search"
          id="search-field"
          type="search"
        />
      </Box>
      <Box sx={{ display: "flex", padding: 1 }}>
        <Box sx={{ width: "480px" }}>Name</Box>
        <Box sx={{ width: "110px" }}>Store</Box>
        <Box sx={{ width: "119px" }}>Product Id</Box>
        <Box sx={{ width: "200px" }}>Classification</Box>
        <Box sx={{ width: "100px" }}>Score</Box>
        <Box sx={{ width: "100px" }}>URL</Box>
      </Box>
      <div
        id="scrollableDiv"
        style={{
          height: 600,
          overflow: "auto",
          display: "flex",
        }}
      >
        <InfiniteScroll
          dataLength={items?.length}
          //next={fetchMoreData}
          //hasMore={true}
          // loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {items?.map((comp, index) => (
            <Box key={index}>
              <SearchItem comp={comp} index={index} categories={categories} />
            </Box>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default Search;
