import { useEffect } from "react";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";
import { Navigate } from "react-router-dom";

function CustomerStoresAPI({ setCustomerStoreData, customer }) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
  const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

  const { status, data } = useCustomerStores(endpoint, hasuraSecret, customer);

  useEffect(() => {
    setCustomerStoreData(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useCustomerStores(endpoint, hasuraSecret, customer) {
  let variables = {};
  if (customer) {
    variables = { customer: customer };
  }

  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  if (customer) {
    return useQuery(
      ["customerStoresCustomer"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getStores($customer: String!) {
              customer_stores(
                where: {
                  customer: { group_detail: { client: { _eq: $customer } } }
                }
                order_by: { updated_at: desc }
              ) {
                id
                store
                uid
                status
                pwd
                updated_at
                customer {
                  group_detail {
                    client
                  }
                  email
                }
              }
            }
          `,
          variables,
          requestHeaders
        );
        return data.customer_stores;
      },
      {
        cacheTime: 0,
      }
    );
  } else {
    return useQuery(
      ["customerStoresFoodMaven"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getStores {
              customer_stores(
                where: { customer: { group_detail_id: { _is_null: true } } }
              ) {
                id
                store
                uid
                status
                updated_at
                customer {
                  group_detail_id
                  email
                }
              }
            }
          `,
          variables,
          requestHeaders
        );
        return data.customer_stores;
      },
      {
        cacheTime: 0,
      }
    );
  }
}
export default CustomerStoresAPI;
