import { Typography } from "@mui/material";
import { useEffect } from "react";
function Classification({ setTitle }) {
  useEffect(() => {
    setTitle("Classification");
  }, []);
  return <Typography>Classificaiton</Typography>;
}

export default Classification;
