import { makeStyles } from "@mui/styles";
import clsx from "clsx";

function LoadingSpinner() {
  const useStyles = makeStyles((theme) => ({
    spinner: {
      display: "inline-block",
      width: "80px",
      height: "80px",
    },
    spinnerAfter: {
      content: " ",
      display: "block",
      width: "24px",
      height: "24px",
      margin: "8px",
      "border-radius": "50%",
      border: "6px solid #aaa",
      "border-color": "#aaa transparent #aaa transparent",
      animation: "$spinner 1.2s linear infinite",
    },
    "@keyframes spinner": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));

  const classes = useStyles();
  return <div className={clsx(classes.spinner, classes.spinnerAfter)}></div>;
}
//[classes.spinnerAfter]: "exit",
export default LoadingSpinner;
