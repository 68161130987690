import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";

const endpoint = process.env.REACT_APP_SUPPLIER_MATCHING_API_ENDPOINT;
const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

function fetchProductsAndServices({ setProductsAndServices }) {
  const { status, data } = useProductsAndServices(endpoint, hasuraSecret);

  useEffect(() => {
    setProductsAndServices(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useProductsAndServices(endpoint, hasuraSecret) {
  let variables = {};
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  return useQuery(
    ["ProductsAndServices"],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query GetProductsAndServices {
            products_and_services(order_by: { name: desc }) {
              id
              name
            }
          }
        `,
        variables,
        requestHeaders
      );
      console.log(data.products_and_services);
      return data.products_and_services;
    },
    {
      cacheTime: 0,
    }
  );
}

export default fetchProductsAndServices;
