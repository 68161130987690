import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import AttributionIcon from "@mui/icons-material/Attribution";
import PeopleIcon from "@mui/icons-material/People";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CategoryIcon from "@mui/icons-material/Category";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import LockIcon from "@mui/icons-material/Lock";
import ErrorIcon from "@mui/icons-material/Error";
import RegIcon from "@mui/icons-material/HowToReg";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StoreFrontIcon from "@mui/icons-material/Storefront";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PlaceIcon from "@mui/icons-material/Place";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Admin
    </ListSubheader>
    <ListItem button component={Link} to="/taxonomy">
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <ListItemText primary="Taxonomy" />
    </ListItem>
    <ListItem button component={Link} to="/trainingData">
      <ListItemIcon>
        <ModelTrainingIcon />
      </ListItemIcon>
      <ListItemText primary="Training Data" />
    </ListItem>
    <ListItem button component={Link} to="/comp-admin">
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText primary="Comp Admin" />
    </ListItem>
  </div>
);
export const supplierMatchingListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Supplier Matching
    </ListSubheader>
    <ListItem button component={Link} to="/suppliers">
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Suppliers" />
    </ListItem>
    <ListItem button component={Link} to="/categories">
      <ListItemIcon>
        <WorkspacesIcon />
      </ListItemIcon>
      <ListItemText primary="Categories" />
    </ListItem>
    <ListItem button component={Link} to="/clients">
      <ListItemIcon>
        <StoreFrontIcon />
      </ListItemIcon>
      <ListItemText primary="Clients" />
    </ListItem>
    <ListItem button component={Link} to="/locations">
      <ListItemIcon>
        <PlaceIcon />
      </ListItemIcon>
      <ListItemText primary="Locations" />
    </ListItem>
    <ListItem button component={Link} to="/productsAndServices">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Products / Services" />
    </ListItem>
  </div>
);

export const entegraListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Entegra SSA
    </ListSubheader>
    <ListItem button component={Link} to="/entegra-users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Signups" />
    </ListItem>
    <ListItem button component={Link} to="/entegra-credentials">
      <ListItemIcon>
        <LockIcon />
      </ListItemIcon>
      <ListItemText primary="Store Credentials" />
    </ListItem>
    <ListItem button component={Link} to="/entegra-registrations">
      <ListItemIcon>
        <RegIcon />
      </ListItemIcon>
      <ListItemText primary="SSA Registered" />
    </ListItem>
    <ListItem button component={Link} to="/entegra-downloads">
      <ListItemIcon>
        <CloudDownloadIcon />
      </ListItemIcon>
      <ListItemText primary="Downloads" />
    </ListItem>
  </div>
);

export const ssaListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      SSA
    </ListSubheader>
    <ListItem button component={Link} to="/ssa-users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Signups" />
    </ListItem>
    <ListItem button component={Link} to="/ssa-credentials">
      <ListItemIcon>
        <LockIcon />
      </ListItemIcon>
      <ListItemText primary="Store Credentials" />
    </ListItem>
    <ListItem button component={Link} to="/ssa-registrations">
      <ListItemIcon>
        <RegIcon />
      </ListItemIcon>
      <ListItemText primary="SSA Registered" />
    </ListItem>
    <ListItem button component={Link} to="/ssa-downloads">
      <ListItemIcon>
        <CloudDownloadIcon />
      </ListItemIcon>
      <ListItemText primary="Downloads" />
    </ListItem>
  </div>
);
export const purchaseHistoryCollectorListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Purchase History
    </ListSubheader>
    <ListItem button component={Link} to="/ph-schedules">
      <ListItemIcon>
        <ScheduleIcon />
      </ListItemIcon>
      <ListItemText primary="Schedules" />
    </ListItem>
    <ListItem button component={Link} to="/failedPHC">
      <ListItemIcon>
        <ErrorIcon />
      </ListItemIcon>
      <ListItemText primary="Collection Failures" />
    </ListItem>
  </div>
);

export const priceCollectorListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Price Collectors
    </ListSubheader>
    <ListItem button component={Link} to="/pc-schedules">
      <ListItemIcon>
        <ScheduleIcon />
      </ListItemIcon>
      <ListItemText primary="Schedules" />
    </ListItem>
    <ListItem button component={Link} to="/failedPriceCollections">
      <ListItemIcon>
        <ErrorIcon />
      </ListItemIcon>
      <ListItemText primary="Collection Failures" />
    </ListItem>
  </div>
);
export const matchingListItems = (
  <div>
    <ListSubheader inset style={{ color: "#ff6f00" }}>
      Matching
    </ListSubheader>
    <ListItem button disabled={true}>
      <ListItemIcon>
        <ViewModuleIcon />
      </ListItemIcon>
      <ListItemText primary="By Category" />
    </ListItem>
    <ListItem button disabled={true}>
      <ListItemIcon>
        <AttributionIcon />
      </ListItemIcon>
      <ListItemText primary="By Customer" />
    </ListItem>
  </div>
);
