import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  mainListItems,
  entegraListItems,
  supplierMatchingListItems,
  priceCollectorListItems,
  purchaseHistoryCollectorListItems,
} from "./listItems";

import Taxonomy from "./Admin/Taxonomy";
import CompAdmin from "./Admin/CompAdmin";
import Classification from "./Admin/Classification";
import TrainingData from "./Admin/ML";
import Users from "./SSA/Users";
import Credentials from "./SSA/Credentials";
import CredentialsDetail from "./SSA/CredentialsDetail";
import Registrations from "./SSA/Registrations";
import Downloads from "./SSA/Downloads";
import PCSchedules from "./PriceCollectors/Schedules";
import PHSchedules from "./PurchaseHistory/Schedules";
import ManualRun from "./PriceCollectors/ManualRun";
import LeafTrainingData from "./Admin/ML/LeafTrainingData";
import Suppliers from "./SupplierMatching/Suppliers";
import Categories from "./SupplierMatching/Categories";
import Clients from "./SupplierMatching/Clients";
import Locations from "./SupplierMatching/Locations";
import ProductsAndServices from "./SupplierMatching/ProductsAndServices";
import FailedPHC from "./PurchaseHistory/FailedPHC";
import FailedPriceCollections from "./PriceCollectors/FailedPriceCollections";
import PurchaseHistory from "./PurchaseHistory/PurchaseHistory";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function HomeContent({ signOut }) {
  const [title, setTitle] = useState("Dashboard");
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          style={{ background: "#1b5e20" }}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            <Button onClick={() => signOut()} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
              background: "#1b5e20",
            }}
          >
            <Box mr={4}>
              <img src="logo-white.svg" alt="logo" style={{ maxWidth: 140 }} />
            </Box>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{supplierMatchingListItems}</List>
          <Divider />
          <List>{entegraListItems}</List>
          <Divider />
          <List>{priceCollectorListItems}</List>
          <Divider />
          <List>{purchaseHistoryCollectorListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Routes>
              <Route
                path="/comp-admin"
                element={<CompAdmin setTitle={setTitle} />}
              />
              <Route
                path="/taxonomy"
                element={<Taxonomy setTitle={setTitle} />}
              />
              <Route
                path="/trainingData"
                element={<TrainingData setTitle={setTitle} />}
              />
              <Route
                path="/classification"
                element={<Classification setTitle={setTitle} />}
              />
              <Route
                path="/entegra-users"
                element={<Users setTitle={setTitle} customer="Entegra" />}
              />
              <Route
                path="/entegra-credentials"
                element={<Credentials setTitle={setTitle} customer="Entegra" />}
              />
              <Route
                path="/credentials-detail"
                element={
                  <CredentialsDetail setTitle={setTitle} customer="Entegra" />
                }
              />
              <Route
                path="/entegra-registrations"
                element={
                  <Registrations setTitle={setTitle} customer="Entegra" />
                }
              />
              <Route
                path="/entegra-downloads"
                element={<Downloads setTitle={setTitle} customer="Entegra" />}
              />
              <Route
                path="/ssa-users"
                element={<Users setTitle={setTitle} customer="" />}
              />
              <Route
                path="/ssa-credentials"
                element={<Credentials setTitle={setTitle} customer="" />}
              />
              <Route
                path="/ssa-registrations"
                element={<Registrations setTitle={setTitle} customer="" />}
              />
              <Route
                path="/ssa-downloads"
                element={<Downloads setTitle={setTitle} customer="" />}
              />
              <Route
                path="/purchaseHistory"
                element={<PurchaseHistory setTitle={setTitle} />}
              />
              <Route
                path="/failedPHC"
                element={<FailedPHC setTitle={setTitle} />}
              />
              <Route
                path="/ph-schedules"
                element={<PHSchedules setTitle={setTitle} />}
              />
              <Route
                path="/failedPriceCollections"
                element={<FailedPriceCollections setTitle={setTitle} />}
              />
              <Route
                path="/pc-schedules"
                element={<PCSchedules setTitle={setTitle} />}
              />
              <Route
                path="/pc-schedules-manual-run"
                element={<ManualRun setTitle={setTitle} />}
              />
              <Route
                path="/leaf-training-data"
                element={<LeafTrainingData setTitle={setTitle} />}
              />
              <Route
                path="/suppliers"
                element={<Suppliers setTitle={setTitle} />}
              />
              <Route
                path="/categories"
                element={<Categories setTitle={setTitle} />}
              />
              <Route
                path="/clients"
                element={<Clients setTitle={setTitle} />}
              />
              <Route
                path="/locations"
                element={<Locations setTitle={setTitle} />}
              />
              <Route
                path="/productsAndServices"
                element={<ProductsAndServices setTitle={setTitle} />}
              />
              <Route
                path="/location-edit"
                element={<LeafTrainingData setTitle={setTitle} />}
              />
            </Routes>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Home({ signOut }) {
  return <HomeContent signOut={signOut} />;
}
