import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ProductsAndServicesList from "./ProductsAndServicesList";

function ProductsAndServices({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Products and Services");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ProductsAndServicesList setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default ProductsAndServices;
