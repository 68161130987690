import { Button, TextField, Autocomplete } from "@mui/material";
import React, { useEffect } from "react";
import { getCharacteristicsAndUom } from "../../../api/CompAdminAPI";

function CategoryName({
  categoryId,
  categoryName,
  categories,
  setNewCategoryId,
  setNewCategoryName,
}) {
  const saveCategory = async (object, cat) => {
    if (cat && cat.label) {
      const newCat = cat.label;
      const newCatId = cat.value;

      const characteristicsAndUom = await getCharacteristicsAndUom(categoryId);

      console.log("save Uom: " + JSON.stringify(characteristicsAndUom.uom));
      console.log("save newCategory: " + JSON.stringify(newCat));
      console.log("save newCategoryId: " + JSON.stringify(newCatId));
      setNewCategoryId(newCatId);
      setNewCategoryName(newCat);
    }
  };

  useEffect(async () => {
    console.log("categoryname category: " + JSON.stringify(categoryName));
    console.log("categoryname categoryId: " + JSON.stringify(categoryId));
    setNewCategoryName(categoryName);
    setNewCategoryId(categoryId.toString());
  }, []);

  return (
    <>
      {categoryName && (
        <Autocomplete
          disablePortal
          id="categories"
          options={categories}
          sx={{ width: 300 }}
          onChange={saveCategory}
          defaultValue={categoryName}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={true}
              // placeholder={newCategory}
              size="small"
            />
          )}
        />
      )}
    </>
  );
}

export default CategoryName;
