import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import SupplierList from "./SupplierList";

function Suppliers({ setTitle }) {
  const queryClient = new QueryClient();
  useEffect(() => {
    setTitle("Suppliers");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SupplierList setTitle={setTitle} />
    </QueryClientProvider>
  );
}

export default Suppliers;
