import { useEffect } from "react";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";
import { Navigate } from "react-router-dom";

function CustomerRegistrationAPI({ setCustomerRegistrationData, customer }) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
  const hasuraSecret = process.env.REACT_APP_HASURA_SECRET;

  const { status, data } = useCustomerRegistration(
    endpoint,
    hasuraSecret,
    customer
  );

  useEffect(() => {
    setCustomerRegistrationData(data);
  });

  return (
    <>
      {status === "error" ? (
        <Navigate to="/" />
      ) : status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useCustomerRegistration(endpoint, hasuraSecret, customer) {
  let variables = {};
  if (customer) {
    variables = { customer: customer };
  }
  const requestHeaders = {
    "x-hasura-admin-secret": hasuraSecret,
  };

  if (customer) {
    return useQuery(
      ["customerRegistration"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getRegistration($customer: String!) {
              registration(
                where: {
                  customer_registration: {
                    group_detail: { client: { _eq: $customer } }
                  }
                }
                distinct_on: cid
              ) {
                uid
                cid
                activity
                browserType
                updated_at
              }
            }
          `,
          variables,
          requestHeaders
        );
        console.log(data.registration);
        return data.registration;
      },
      {
        cacheTime: 0,
      }
    );
  } else {
    return useQuery(
      ["customerRegistrationFoodmaven"],
      async () => {
        const data = await request(
          endpoint,
          gql`
            query getRegistration {
              registration(
                where: {
                  customer_registration: { group_detail_id: { _is_null: true } }
                }
                distinct_on: cid
              ) {
                uid
                cid
                activity
                browserType
                updated_at
              }
            }
          `,
          variables,
          requestHeaders
        );
        console.log(data.registration);
        return data.registration;
      },
      {
        cacheTime: 0,
      }
    );
  }
}
export default CustomerRegistrationAPI;
