import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LeafTrainingDataAPI } from "../../../api/LeafTrainingDataAPI";
import {
  useUpdateLeafTrainingData,
  useRefreshLeafTrainingData,
} from "../../../api/LeafTrainingDataAPI";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import { FormControlLabel, IconButton } from "@mui/material";

const columns = [
  {
    field: "compName",
    headerName: "Comp",
    headerClassName: "data-grid-header",
    width: 750,
  },
  {
    field: "store",
    headerName: "Store",
    headerClassName: "data-grid-header",
    width: 130,
  },
  {
    field: "storeProductId",
    headerName: "Product Id",
    headerClassName: "data-grid-header",
    width: 170,
  },
];
export default function LeafTrainingData({ setTitle }) {
  const location = useLocation();
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [leafTrainingData, setLeafTrainingData] = useState([]);
  const [gridRefresh, setGridRefresh] = useState(true);
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState([
    {
      field: "count",
      sort: "desc",
    },
  ]);

  // delete user
  const {
    mutateAsync: deleteTrainingData,
    status: deleteStatus,
    isLoading: isLoadingDelete,
    data: deleteData,
    error: deleteErr,
  } = useUpdateLeafTrainingData();

  useEffect(() => {
    // setTitle("Leaf Training Data " + JSON.stringify(location));
  }, [leafTrainingData]);

  const handleClose = async () => {
    navigate("/trainingData");
  };
  const handleClick = async () => {
    console.log("ids " + JSON.stringify(selected));
    let deleted = await deleteTrainingData(selected);
    console.log("deleted " + JSON.stringify(selected));
    const newData = () => {
      const filtered = leafTrainingData.filter((el) => {
        return selected.indexOf(el.id) === -1;
      });
      return filtered;
    };
    setLeafTrainingData(newData);
  };

  const handleSelection = (ids) => {
    if (ids.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    console.log("ids " + JSON.stringify(ids));

    setSelected(ids);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          Leaf Training Data - {location.state.categoryname}
        </Typography>
        <Button
          type="button"
          variant="contained"
          onClick={handleClose}
          color="warning"
        >
          Cancel
        </Button>
        <Button disabled={disabled} variant="contained" onClick={handleClick}>
          Delete Records Selected
        </Button>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <LeafTrainingDataAPI
          categoryid={location.state.categoryid}
          setLeafTrainingData={setLeafTrainingData}
        />
        {leafTrainingData ? (
          <DataGrid
            rows={leafTrainingData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            density="compact"
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelection}
            getRowId={(row) => row.id}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        ) : null}
      </Box>
    </div>
  );
}
